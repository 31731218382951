.reservation-header {
  display: flex;
  justify-content: space-between;
  padding-top: 1.625rem;
  margin-bottom: 4rem;

  @include tablet {
    margin-bottom: 3rem;
  }

  @include mobile {
    margin-bottom: 2rem;
  }
}

.reservation-header-divider {
  height: 1px;
  width: 100%;
  background: $Light-grayish-blue;
}

.reservation-step {
  display: flex;
  align-items: center;

  &.active {
    .reservation-step-number {
      background: $Accent;
    }

    .reservation-step-title {
      font-weight: 600;
      color: $Very-dark-blue;
    }
  }

  &:nth-child(2) {
    width: 100%;

    &::before,
    &::after {
      content: "";
      height: 1px;
      width: 100%;
      background: $Grayish-blue;
      margin: 0 1.5rem;

      @include tablet {
        margin: 0 1rem;
      }
    }
  }

  &-number {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1.75rem;
    min-width: 1.75rem;
    border-radius: 2rem;
    font-weight: 600;
    color: white;
    background: $Light-grayish-blue;
    margin-right: 0.625rem;

    @include mobile {
      &:not(.active) {
        margin-right: unset;
      }
    }
  }

  &-title {
    color: $Dark-grayish-blue;
    letter-spacing: 0.016em;
    white-space: nowrap;

    @include mobile {
      &:not(.active) {
        display: none;
      }
    }
  }
}
