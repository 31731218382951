.special-request {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid $Grayish-blue;

  &-card {
    max-width: 560px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem;

    @include mobile {
      padding: 2rem 1.5rem 2rem;
    }

    &[data-success] {
      gap: 1rem;

      h4 {
        text-align: center;
      }

      svg {
        font-size: 3rem;
        color: $Accent;
        margin-inline: auto;
      }
    }

    .request-legend {
      h4 {
        margin-bottom: 1rem;
      }

      p {
        color: $Dark-grayish-blue;
      }
    }

    fieldset {
      border: none;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }
  }
}
