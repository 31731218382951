.coupon-main {
  display: flex;
  align-items: center;
  padding-top: 3rem;

  h3 {
    margin-bottom: 2.5rem;

    @include mobile {
      margin-bottom: 2rem;
    }
  }

  strong {
    font-weight: 600;
    font-size: 1rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
    max-width: 680px;
    border-radius: 1rem;

    @include mobile {
      max-width: 320px;
    }
  }

  li {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      font-size: 1rem;
      max-width: 60ch;

      @include mobile {
        font-size: 0.875rem;
      }
    }

    .num {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.75rem;
      min-height: 1.75rem;
      background: $Accent;
      border-radius: 50%;
      color: white;
    }
  }
}
