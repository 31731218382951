.discount-modal {
  .discount {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h6 {
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      span {
        padding: 0.25rem;
        background: $Green;
        border-radius: 0.25rem;
        font-weight: 600;
        white-space: nowrap;
        color: white;
        font-size: 0.75rem;
      }
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    gap: 0.5rem;

    &:not(:last-child) {
      border-bottom: 1px solid $Light-grayish-blue;
    }

    p:last-child {
      font-weight: 600;
    }
  }

  details {
    summary {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &::after {
        content: "↓";
        font-size: 1rem;
      }

      p {
        text-decoration: underline;
      }
    }

    &[open] summary::after {
      rotate: 180deg;
    }

    ul {
      margin-top: 0.5rem;
      padding-left: 1.25rem;

      p {
        padding-left: unset !important;
      }
    }
  }
}
