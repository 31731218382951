.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-block: 4rem;
  background: $Footer-bg;
  border-radius: 0.75rem;

  @include mobile {
    padding-block: 3rem;
    gap: 0.5rem;
  }

  .title {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $Dark-grayish-blue;

    @include mobile {
      font-size: 0.875rem;
    }
  }
}

.countdown {
  display: flex;
  gap: 1.5rem;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    span:first-child {
      font-size: 3.5rem;
      letter-spacing: -0.05em;
      font-weight: 500;
      font-variant-numeric: tabular-nums;

      @include mobile {
        font-size: 2.5rem;
      }
    }

    span:last-child {
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 0.12em;
      color: $Dark-grayish-blue;

      @include mobile {
        font-size: 0.625rem;
      }
    }
  }
}
