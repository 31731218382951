.archive-main {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    max-width: 980px;
  }

  .events {
    width: 100%;

    .event-card {
      height: fit-content;
    }
  }

  .btn-back {
    align-self: flex-start;
  }
}
