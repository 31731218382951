.footer {
  position: relative;
  margin-top: 6rem;
  background: $Footer-bg;
  color: rgba($Dark-grayish-blue, 0.7);
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;

  @include tablet {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    &:first-child {
      border-bottom: 1px solid $Grayish-blue;
      padding-top: 0.75rem;
      padding-bottom: 1.75rem;
    }

    @include tablet {
      flex-direction: column;
    }

    @include mobile {
      gap: 1.5rem;
    }
  }

  &-nav {
    display: flex;
    gap: 1.5rem;

    @include mobile {
      flex-direction: column;
      align-items: center;
      gap: 1.25rem;
    }

    a {
      color: #838c97;
      white-space: nowrap;

      &:hover {
        color: $Dark-grayish-blue;
        text-decoration: underline;
      }

      @include mobile {
        font-size: 1rem;
      }
    }
  }

  .logo-footer {
    opacity: 0.7;
  }

  .copy {
    white-space: nowrap;
  }

  .socials {
    place-self: center;
    display: flex;

    a {
      font-size: 1.5rem;
      color: #959da8;

      &:hover {
        color: $Accent;
      }

      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }

  small {
    font-size: 0.75rem;
    // letter-spacing: 0.016em;
    line-height: 1.75em;
    max-width: 65ch;
    text-align: right;

    @include tablet {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}
