.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  position: relative;
  flex: 1;
  opacity: 0;
  transform: scale(0.99);
  animation: mainFadeIn 0.4s ease forwards;

  @keyframes mainFadeIn {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.container {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.auth-main,
.account-main,
.status-main {
  .container {
    border-top: 1px solid $Grayish-blue;
    padding-top: 4rem;

    @include mobile {
      padding-top: 2rem;
    }
  }
}

.pad {
  padding-left: 2rem;
  padding-right: 2rem;

  @include mobile {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
