.event-main {
  .reservation-header {
    padding-top: 6rem;
    margin-bottom: 2.5rem;

    @include mobile {
      padding-top: 4rem;
      margin-bottom: 1.75rem;
    }
  }

  .checkout-promo {
    max-width: 580px;
    margin-inline: auto;
    margin-bottom: 3rem;
  }

  @import "countdown";
}

.event-header {
  margin-bottom: 2.5rem;

  @include mobile {
    margin-bottom: 1.5rem;
  }

  button {
    margin-bottom: 1.25rem;

    @include mobile {
      margin-bottom: 1.5rem;
    }
  }

  .event-title-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include desktop {
      justify-content: center;
      align-items: center;
      padding: 5rem 2rem;
      color: white;
      text-align: center;
      border-radius: 0.625rem;
      background: no-repeat url("../../../icons/slider-bg-left.svg") left bottom,
        no-repeat url("../../../icons/slider-bg-right.svg") right top, $Accent;
    }

    @include tablet {
      background-size: 50%, 50%;
    }

    i {
      top: 0;
      margin-top: -1rem;
      border: 2px solid rgba(white, 0.4);
      color: rgba(white, 0.8);
      padding: 0.375rem 0.75rem;
      border-radius: 0.5rem;
      font-style: normal;

      @include mobile {
        margin-top: unset;
        border: none;
        width: fit-content;
        border: 1px solid $Light-grayish-blue;
        font-size: 0.75rem;
        color: $Dark-grayish-blue;
        padding: 0.375rem 0.75rem;
        border-radius: 0.375rem;
        font-style: normal;
      }
    }

    .event-title {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      h2 {
        font-size: clamp(2rem, -0.5rem + 5.2vw, 2.5rem);
        text-wrap: balance;

        @include mobile {
          font-size: 1.75rem;
        }
      }

      @include desktop {
        justify-content: center;
        align-items: center;
      }
    }

    .capacity {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 15rem;

      @include mobile {
        width: min(20rem, 100%);
      }

      .legend {
        display: flex;
        justify-content: space-between;

        span {
          color: rgba(white, 0.8);

          @include mobile {
            color: $Dark-grayish-blue;
          }
        }

        strong {
          font-weight: 600;
        }
      }

      .progress,
      .indicator {
        height: 0.5rem;
        border-radius: 0.5rem;
      }

      .progress {
        width: 100%;
        background: rgba(white, 0.3);

        @include mobile {
          background: hsl(220, 21%, 93%);
        }
      }

      .indicator {
        background: white;

        @include mobile {
          background: $Accent;
        }
      }
    }

    .event-types-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.25rem;

      @include mobile {
        margin-top: 0;
        align-items: flex-start;
      }
    }

    .event-types {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      @include mobile {
        align-items: flex-start;
        justify-content: flex-start;
      }

      small {
        opacity: 0.8;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 0.125rem 0.5rem;
      }

      li {
        font-weight: 600;
        white-space: nowrap;
      }
    }

    .event-date {
      width: fit-content;
      padding: 0.625rem 1.25rem;
      border-radius: 0.5rem;
      background: rgba(white, 0.1);
      font-size: 1rem;
      letter-spacing: 0.016em;
      white-space: nowrap;

      @include mobile {
        padding: unset;
        border-radius: unset;
        background: unset;
        font-size: inherit;
        font-weight: inherit;
        color: $Dark-grayish-blue;
      }
    }
  }
}

.event-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50rem;
  margin: 0 auto 4rem;

  @include mobile {
    margin-bottom: 2.5rem;
  }

  .swiper-slide {
    border-radius: 0.25rem;
    overflow: hidden;
    background: $Light-grayish-blue;
    max-height: 30rem;

    img {
      min-height: 12rem;
    }
  }
}

.event-suits {
  display: flex;
  flex-direction: column;
  align-items: center;

  .package-select {
    display: grid;
    grid-auto-flow: column;
    gap: 1.5rem;
    margin-bottom: 2.5rem;

    @include mobile {
      grid-auto-flow: unset;
      gap: 0.75rem;
      margin-bottom: 2rem;
    }
  }

  .package-title {
    text-align: center;
    margin-bottom: 2.5rem;

    @include mobile {
      text-align: left;
      margin-bottom: 2rem;
    }

    h4 {
      margin-bottom: 0.5rem;
    }

    p {
      color: $Dark-grayish-blue;
    }
  }
}
