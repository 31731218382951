$Background: hsl(220, 23%, 97%);
$Footer-bg: hsl(220, 21%, 95%);
$Very-dark-blue: hsl(215, 25%, 24%);
$Dark-grayish-blue: hsl(216, 13%, 44%);
$Grayish-blue: hsl(215, 12%, 73%);
$Light-grayish-blue: hsl(216, 7%, 87%);
$Green: hsl(138, 71%, 37%);
$Red: hsl(358, 71%, 56%);
$Orange: hsl(38, 100%, 70%);
$Accent: hsl(211, 100%, 43%);

$laptop: 1024px;
$tablet: 768px;
$mobile: 576px;
