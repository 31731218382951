.reservation-main {
  .auth-container {
    border-bottom: 1px solid $Grayish-blue;
    padding-bottom: 5rem;
    margin-bottom: 4rem;

    @include tablet {
      padding-bottom: 3rem;
      margin-bottom: 3rem;
    }

    @include mobile {
      padding-bottom: 2.5rem;
      margin-bottom: 2rem;
    }
  }

  .reservation-list-container {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 2fr 1fr;
    gap: 3rem;

    @include laptop {
      grid-template-columns: 1.7fr 1fr;
      gap: 1.5rem;
    }

    @include tablet {
      grid-template-columns: unset;
      gap: unset;
    }
  }

  .reservation-list {
    &-group {
      > .btn-outline {
        margin-top: 0.75rem;
        // margin-bottom: 1.5rem;
      }
    }

    &-group:not(:last-of-type) {
      margin-bottom: 4rem;

      @include tablet {
        margin-bottom: 3rem;
      }
    }
  }
}
