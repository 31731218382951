.swiper {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include desktop {
    &:hover {
      .prev,
      .next {
        opacity: 1;
      }
    }
  }

  .swiper-slide {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: 2;
    @extend %tr;

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  .prev {
    left: 1rem;
  }

  .next {
    right: 1rem;
  }
}

.swiper-pagination {
  margin-top: 1rem;
  display: grid;
  height: 0.75rem;
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;
  gap: 1rem;
}

.swiper-pagination-bullet {
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background: $Accent;
  @extend %tr;

  &:hover:not(.swiper-pagination-bullet-active) {
    transform: scale(1.2);
  }

  &-active {
    cursor: unset;
    width: 0.75rem;
    height: 0.75rem;
  }
}
