.card {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 3px 22px rgba($Very-dark-blue, 0.05);
}

.card-list {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  grid-gap: 3rem;
  align-items: start;

  @include laptop {
    grid-gap: 1.5rem;
  }

  @include tablet {
    grid-template-columns: unset;
    justify-items: center;

    & > * {
      width: min(35rem, 100%);
    }
  }
}

.card-container {
  padding: 1.5rem;

  h5 {
    font-size: useClamp(460, 576, 1, 1.25);
  }

  @include mobile {
    padding: 1.25rem;
  }
}

.card-event-name {
  padding: 0.5rem 0;
  margin-bottom: 1.25rem;

  @include mobile {
    padding: 0.25rem 0;
    margin-bottom: 1rem;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .flex-1 {
    flex: 1;
  }

  .btn-delete {
    margin-left: 1rem;
  }
}

.card-img {
  position: relative;
  width: 100%;
  max-width: 8.75rem;
  height: 5.75rem;
  border-radius: 0.25rem;
  overflow: hidden;
  margin-right: 1rem;

  @include laptop {
    max-width: 8rem;
    height: 5rem;
  }

  @include mobile {
    max-width: 5.5rem;
    height: 3.5rem;
    margin-right: 0.75rem;
  }

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
