@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: $Very-dark-blue;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 700;
}

h1 {
  font-size: useClamp(768, 960, 2.25, 3);
}

h2 {
  font-size: clamp(2rem, 0.79rem + 5.1vw, 2.625rem);
}

h3 {
  font-size: clamp(1.5rem, 0.75rem + 2vw, 2rem);
}

h4 {
  line-height: 1.4em;
  font-size: clamp(1.25rem, 0.875rem + 1vw, 1.5rem);
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.5em;
}

p,
li {
  font-size: 0.875rem;
  letter-spacing: 0.016em;
  line-height: 1.7em;
}

small {
  font-size: 0.75rem;
  letter-spacing: 0.008em;
  line-height: 1.125rem;
}

code {
  background: rgba($Light-grayish-blue, 0.4);
  border-radius: 0.5rem;
  padding: 0.2em 0.4em;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.error-title {
  font-size: 1rem;
  text-align: center;
  color: $Dark-grayish-blue;
}
