.radio-icons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

  label {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: unset;
    height: 3.5rem;
    border: 1px solid $Grayish-blue;
    border-radius: 0.5rem;
    padding-inline: 1rem 2.5rem;

    @include mobile {
      width: 100%;
    }

    &[data-active] {
      border-color: $Accent;
      color: $Accent;
      background: rgba($Accent, 0.02);
    }

    @include hover {
      box-shadow: 0 0 0 4px rgba($Accent, 0.05);

      .checkmark {
        box-shadow: none;
      }
    }
  }

  svg {
    font-size: 2rem;
  }

  .checkmark {
    left: unset;
    top: 0.5rem;
    right: 0.5rem;
  }
}
