.status-main {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  svg {
    font-size: 4rem;
    margin-bottom: 1rem;

    &.icon-success {
      color: $Accent;
    }
    &.icon-failure {
      color: $Red;
    }
  }

  h4 {
    margin-bottom: 1rem;
  }

  p {
    max-width: 480px;
    text-align: center;
    color: $Dark-grayish-blue;
    margin-bottom: 2rem;
  }

  .btn {
    max-width: 20rem;
    width: 100%;

    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
}
