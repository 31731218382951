.payment-status {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  white-space: nowrap;
  max-width: 100%;

  &-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mobile {
      max-width: useClamp(400, 540, 3, 6);
    }
  }

  &-icon {
    display: block;
    min-width: 0.5rem;
    height: 0.5rem;
    background: $Grayish-blue;
    border-radius: 50%;
    margin-left: 0.5rem;

    &.success {
      background: $Green;
    }
    &.partially {
      background: $Orange;
    }
    &.failure {
      background: $Red;
    }
  }
}
