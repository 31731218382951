.countdown-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: white;
  background: rgba(#003066, 0.6);

  @include mobile {
    gap: 0.25rem;
  }

  .title {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @include mobile {
      font-size: 0.875rem;
    }
  }
}

.countdown {
  display: flex;
  gap: 1.25rem;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    span:first-child {
      font-size: 3.25rem;
      letter-spacing: -0.05em;
      font-weight: 500;
      font-variant-numeric: tabular-nums;

      @include mobile {
        font-size: 2.5rem;
      }
    }

    span:last-child {
      font-size: 0.625rem;
      text-transform: uppercase;
      letter-spacing: 0.12em;
    }
  }
}
