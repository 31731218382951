.suit-info {
  border-top: 1px solid $Grayish-blue;
  padding-top: 1rem;

  &-header {
    margin-bottom: 1rem;
  }

  &-title {
    h5 {
      margin-bottom: 0.25rem;

      @include mobile {
        margin-bottom: 0.125rem;
      }
    }

    span {
      font-size: 0.75rem;
      text-transform: lowercase;
      color: $Dark-grayish-blue;
    }
  }

  &-package {
    position: relative;
    background: $Background;
    padding: 1rem;
    border-radius: 0.375rem;
    margin-bottom: 1.25rem;

    @include mobile {
      padding: 1rem 0.75rem;
    }

    strong {
      font-weight: 600;
    }
  }
}

.participants-list {
  .participant {
    max-width: 25rem;

    &:not(:only-of-type):not(:last-of-type) {
      padding-bottom: 1rem;
      border-bottom: 1px solid $Light-grayish-blue;
      margin-bottom: 1.25rem;
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      margin-bottom: 0.75rem;
    }

    &-tags {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 0.25rem;
      font-size: 0.75rem;
    }

    ul {
      width: 100%;
    }

    li {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      p {
        color: $Dark-grayish-blue;
      }

      .discount {
        color: $Green;
      }

      .total {
        font-weight: 600;
        color: $Very-dark-blue;
      }
    }
  }
}
