.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba($Very-dark-blue, 0.7);
  width: 100%;
  height: 100%;

  @include mobile {
    display: flex;
    align-items: flex-end;
  }

  .container {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-height: 100vh;

    @include mobile {
      max-height: 80dvh;
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.pad {
    @include mobile {
      padding: unset;
    }
  }

  .modal {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    max-width: 40rem;
    padding: 4rem;
    overflow-y: auto;
    animation: 0.4s scaleIn forwards;
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
      width: 8px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(black, 0.2);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    @include mobile {
      padding: 3rem 1.5rem 4rem;
      border-radius: 0.5rem 0.5rem 0 0;

      translate: 0 100%;
      animation: 0.4s slideIn forwards;
    }

    .close-modal {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 1.5rem;
      color: $Grayish-blue;

      &:hover {
        color: $Dark-grayish-blue;
      }
    }
  }

  header {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  p,
  ol {
    color: $Dark-grayish-blue;
  }

  ol {
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
      &::marker {
        font-weight: 600;
        color: $Very-dark-blue;
      }
    }
  }

  .article {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    p,
    h6,
    ol {
      padding-left: 1.25rem;
    }

    h6 {
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 0.5rem;
        height: 0.5rem;
        border: 0.125rem solid $Accent;
        border-radius: 50%;
      }
    }
  }
}

@keyframes scaleIn {
  from {
    scale: 0.8;
  }
  50% {
    scale: 1.02;
  }
  to {
    scale: 1;
  }
}

@keyframes slideIn {
  to {
    translate: 0;
  }
}
