.account-main {
  .auth-card {
    margin-bottom: 2.5rem;
  }

  .container > * {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.account-user {
  margin-bottom: 2.5rem;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 2rem;
  align-items: center;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $Grayish-blue;

  .user-info {
    overflow: hidden;

    h4 {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.25rem;

      svg {
        min-width: 1.25rem;
      }

      b {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    span {
      letter-spacing: 0.016em;
      color: $Dark-grayish-blue;
    }
  }
}

.account-orders {
  h5 {
    margin-bottom: 1.5rem;
  }
}

.empty-orders {
  background: $Footer-bg;
  padding: 1.5rem;
  min-height: 4rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;

  &.loading {
    animation: fade 1s ease infinite;

    @keyframes fade {
      0% {
        opacity: 50%;
      }
      50% {
        opacity: 100%;
      }
      100% {
        opacity: 50%;
      }
    }
  }

  span {
    color: $Dark-grayish-blue;
    font-weight: 600;
  }
}
