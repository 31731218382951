.error-main {
  display: flex;
  align-items: center;
  margin-top: 4rem;

  &.empty-tours {
    position: relative;

    .empty-tours-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.75rem;
      max-width: 25rem;

      @include mobile {
        gap: 0.5rem;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: -10;
      max-width: 100%;
      height: auto;
      margin-bottom: unset;

      @include mobile {
        margin-top: 4rem;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  svg {
    margin-bottom: 1rem;
    font-size: 5rem;
    color: $Grayish-blue;
  }

  .error-title {
    margin-bottom: 1rem;
  }

  .btn {
    max-width: 20rem;
    width: 100%;

    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
}

.error-code {
  font-size: 5rem;
  font-weight: 700;
  color: $Dark-grayish-blue;
}
