&-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 30rem;
  min-height: 20rem;
  text-align: center;

  p {
    color: $Dark-grayish-blue;
  }

  svg {
    margin-bottom: 1rem;
    font-size: 3rem;
    color: $Grayish-blue;
  }

  &[data-soldout] {
    position: relative;
    flex-direction: row;
    gap: 2rem;
    max-width: 32rem;
    text-align: left;

    @include mobile {
      flex-direction: column;
      max-width: 25rem;
      gap: 1rem;
      text-align: center;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    h6 {
      color: $Dark-grayish-blue;
      line-height: 1.5;
    }

    svg {
      min-width: 1em;
      font-size: 7rem;
      rotate: -15deg;
      margin-bottom: unset;

      @include mobile {
        font-size: 6rem;
      }
    }
  }
}
