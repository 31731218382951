@use "sass:math";

@mixin laptop {
  @media screen and (max-width:$laptop) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width:$tablet) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width:$mobile) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width:($mobile + 1px)) {
    @content;
  }
}
@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@function useClamp($minWidthPx, $maxWidthPx, $minSizeRem, $maxSizeRem) {
  $minWidth: math.div($minWidthPx, 16);
  $maxWidth: math.div($maxWidthPx, 16);

  $slope: math.div($maxSizeRem - $minSizeRem, $maxWidth - $minWidth);
  $yAxisIntersection: -$minWidth * $slope + $minSizeRem;

  $result: #{"clamp"
    }(
      $minSizeRem * 1rem,
      $yAxisIntersection * 1rem #{"+"} $slope * 100 * 1vw,
      $maxSizeRem * 1rem
    );

  @return $result;
}
