.auth-card {
  max-width: 30rem;
  margin: 0 auto;
  padding: 2rem;

  @include mobile {
    padding: 1.5rem;
  }

  .auth-header {
    border-bottom: 1px solid $Light-grayish-blue;
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;

    @include mobile {
      padding-bottom: 1rem;
    }
  }

  h4 {
    margin-bottom: 0.25rem;
  }

  p {
    color: $Dark-grayish-blue;
  }

  .field {
    margin-bottom: 1.25rem;

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }

  .btn {
    width: 100%;
    margin-top: 0.75rem;
  }

  .info-msg {
    display: block;
    color: $Dark-grayish-blue;
    margin: 1rem 0;
  }
}
