.upcomming {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 3rem;
  max-width: 980px;
  margin-inline: auto;

  h4 {
    text-align: center;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 1rem;

    p {
      white-space: nowrap;
      color: $Dark-grayish-blue;
    }
  }
}
