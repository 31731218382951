.tv {
  height: 100dvh;
  display: grid;
  grid-template-columns: minmax(25rem, 30%) auto;
  color: white;

  &[data-animate] {
    .tvTitle {
      animation: tvTitle 0.7s;
      transform-origin: top;
    }

    .tvInfo {
      animation: tvBg 1s;
    }

    @keyframes tvTitle {
      0% {
        opacity: 0;
        translate: 0 20vh;
      }
    }
    @keyframes tvBg {
      0% {
        background-position: right -50% top -50%;
      }
    }
  }
}

.tvLogo {
  font-size: max(14vw, 15rem);
}

.tvInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 3rem 3rem;
  background: no-repeat url("../../../icons/slider-bg-right.svg") right top/65%,
    $Accent;

  .tvTitle {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    h1 {
      font-size: max(2.5vw, 2.5rem);
    }

    span {
      font-weight: 600;
      opacity: 0.8;
      font-size: max(1.2vw, 1.125rem);
    }

    p {
      margin-top: 1rem;
      font-size: max(1vw, 1rem);
    }
  }
}

.tvQr {
  display: flex;
  align-items: flex-end;
  gap: 1.5rem;

  .qr {
    max-width: 9rem;
    width: 100%;

    svg {
      height: auto;
      max-width: 100%;
      width: 100%;
    }
  }

  p {
    font-size: max(0.9vw, 1rem);
  }
}

.tvImg {
  position: relative;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.tvImgContainer {
  position: absolute;
  top: 0;
  transition: 1s cubic-bezier(0.25, 1, 0.5, 1);
  width: 100%;
  height: 100%;
}
