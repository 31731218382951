.orders-list {
  .table-grid {
    display: grid;
    grid-template-columns: 3fr repeat(3, minmax(4rem, 1fr));
    grid-gap: 1rem;
    align-items: center;
    justify-items: start;
    letter-spacing: 0.008em;

    @include mobile {
      grid-template-columns: 2.5fr 1fr 1fr;

      & > *:nth-child(2) {
        justify-self: flex-end;
        align-items: flex-end;
      }
    }

    & > *:last-child {
      justify-self: flex-end;
    }
  }

  .orders-header {
    padding: 0 1rem;
    white-space: nowrap;
    color: $Dark-grayish-blue;
    margin-bottom: useClamp(580, 720, 0.5, 0.75);

    @include mobile {
      padding: 0 0.75rem;
    }
  }

  &-card {
    padding: 1rem;
    cursor: pointer;
    @extend %tr;

    @include mobile {
      padding: 1rem 0.75rem;
    }

    @include desktop {
      &:hover {
        box-shadow: 0 6px 32px rgba($Very-dark-blue, 0.1),
          0 6px 16px -2px rgba($Very-dark-blue, 0.1);
      }
    }

    &:active {
      box-shadow: 0 6px 32px rgba($Very-dark-blue, 0.1),
        0 6px 16px -2px rgba($Very-dark-blue, 0.1);
    }

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }

  .order-name {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
    }

    span {
      font-weight: 600;
    }

    small {
      color: $Grayish-blue;
      line-height: 1;
    }

    .event-status {
      font-size: 1rem;
      min-width: 1rem;
      color: $Grayish-blue;

      &[data-complete] {
        color: $Accent;
      }
    }
  }

  .order-price {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;

    span {
      font-weight: 600;
      white-space: nowrap;
    }

    small {
      color: $Green;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.125rem;
      line-height: 1;

      svg {
        font-size: 0.625rem;
      }
    }
  }

  .order-name,
  .order-id,
  .order-date,
  .order-price {
    font-size: useClamp(580, 720, 0.75, 0.875);
  }

  .payment-status {
    @include mobile {
      font-size: 0.625rem;

      .payment-status-icon {
        margin-left: 0.25rem;
      }
    }
  }
}
