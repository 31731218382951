button {
  cursor: pointer;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  border: none;
  background: none;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.016em;
  border-radius: 0.5rem;
  padding: 0.875rem 3rem;

  @include tablet {
    width: 100%;
  }

  @include mobile {
    padding: 0.875rem 1.5rem;
  }

  &:disabled {
    pointer-events: none;
  }

  &.small {
    font-size: 0.75rem;
    border-radius: 0.375rem;
    padding: 0.625rem 1.5rem;
  }
}

.btn-primary,
.btn-primary-icon,
.btn-package,
.btn-suit,
.btn-payment {
  color: white;
  background: $Accent;

  @include hover {
    background: mix($Accent, white, 85%);
  }

  &:active {
    background: mix($Accent, white, 75%);
  }
}

.btn-primary {
  &[data-disabled] {
    background: $Grayish-blue;
  }
}

.btn-outline {
  color: $Dark-grayish-blue;
  box-shadow: 0 0 0 1px $Light-grayish-blue;

  @include desktop {
    &:hover {
      box-shadow: 0 0 0 1px $Grayish-blue;
    }
  }

  &:active {
    box-shadow: 0 0 0 1px $Grayish-blue;
  }
}

.btn-border {
  color: white;
  border: 1px solid white;
  max-width: fit-content;

  @include desktop {
    &:hover {
      box-shadow: 0 0 0 1px white;
    }
  }

  &:active {
    box-shadow: 0 0 0 1px white;
  }
}

.btn-package,
.btn-payment {
  &.active {
    background: none;
    border: 1px solid $Grayish-blue;
    color: $Dark-grayish-blue;

    @include desktop {
      &:hover {
        color: $Accent;
        border-color: $Accent;

        .payment-radio {
          border-color: $Accent;
        }
      }
    }

    &:active {
      color: $Accent;
      border-color: $Accent;

      .payment-radio {
        border-color: $Accent;
      }
    }
  }
}

.btn-payment {
  padding: 1.25rem 1.375rem;
  font-weight: 600;
  justify-content: space-between;

  @include mobile {
    padding: 1rem 0.75rem;
  }

  &.active {
    .payment-radio {
      background: none;
      border: 1px solid $Grayish-blue;

      &::after {
        display: none;
      }
    }
  }

  .payment-radio {
    position: relative;
    display: block;
    min-width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: white;
    margin-right: 0.625rem;

    &::after {
      content: "";
      position: absolute;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 9px;
      border: solid $Accent;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  .payment-label {
    text-align: left;

    @include mobile {
      font-size: 0.75rem;
    }
  }

  .payment-price-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: right;
    white-space: nowrap;
    margin-left: 0.75rem;
  }

  .payment-price {
    font-size: 1.25rem;
    font-weight: 700;

    @include mobile {
      font-size: 1rem;
    }
  }

  .full-price {
    font-size: 0.75rem;
    text-decoration: line-through;
    font-weight: 500;
    opacity: 0.8;
  }
}

.btn-suit {
  width: 100%;
  border-radius: 0 0 0.375rem 0.375rem;

  svg {
    margin-right: 0.5rem;
  }

  &.success {
    background: $Green;
  }
}

.btn-primary-icon {
  padding: 0.875rem 2rem;

  &:disabled,
  &.disabled {
    background: $Grayish-blue;
  }

  svg {
    font-size: 1rem;
    transform: rotate(180deg);
    margin-left: 0.75rem;
  }
}

.btn-inline-icon {
  display: flex;
  align-items: center;

  svg {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
}

.account-controls,
.btn-back {
  color: $Dark-grayish-blue;

  @include desktop {
    &:hover {
      color: $Very-dark-blue;

      svg {
        color: $Dark-grayish-blue;
      }
    }
  }

  &:active {
    color: $Very-dark-blue;

    svg {
      color: $Dark-grayish-blue;
    }
  }

  svg {
    color: $Grayish-blue;
  }
}

.account-controls {
  @include mobile {
    &:active {
      svg {
        color: $Very-dark-blue;
      }
    }

    svg {
      margin-right: unset;
      font-size: 1.25rem;
    }
  }
}

.toggle-content {
  color: $Accent;
  white-space: nowrap;

  @include desktop {
    &:hover {
      box-shadow: 0 1px 0 $Accent;
    }
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.btn-delete {
  font-size: 0.75rem;
  color: $Grayish-blue;

  @include mobile {
    span {
      display: none;
    }
  }

  @include desktop {
    &:hover {
      color: $Dark-grayish-blue;
    }
  }

  &:active {
    color: $Dark-grayish-blue;
  }

  svg {
    font-size: 0.875rem;

    @include mobile {
      font-size: 1rem;
      margin-right: unset;
    }
  }
}

.btn-tab {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.875rem 0.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.06em;
  color: $Dark-grayish-blue;

  @include desktop {
    &:hover {
      color: $Very-dark-blue;
    }
  }

  @include mobile {
    font-size: 0.875rem;
  }

  &.active {
    color: $Accent;
    pointer-events: none;
  }

  svg {
    font-size: 1.25rem;
  }
}

.btn-upper {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;
  color: $Grayish-blue;
  transition: 0.2s;

  &:disabled {
    pointer-events: none;
  }

  &:not(:disabled) {
    color: $Accent;
  }

  &[data-delete] {
    color: $Grayish-blue;

    &:hover {
      color: $Red;
    }
  }
}

.btn-inline {
  cursor: pointer;
  color: $Accent;

  @include desktop {
    &:hover {
      text-decoration: underline;
    }
  }

  &:active {
    text-decoration: underline;
  }
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;

  &[data-theme="light"] {
    color: $Dark-grayish-blue;
  }

  @include desktop {
    &:hover {
      color: $Very-dark-blue;
    }
  }

  &:active {
    color: $Very-dark-blue;
  }
}
