.text-main {
  .container {
    max-width: 45rem;
  }

  ol {
    list-style: none;
    counter-reset: cupcake;

    & ol,
    & ul {
      margin-top: 0.625rem;
      padding-left: 1rem;
    }

    & ul {
      margin-bottom: unset;

      li::before {
        color: $Very-dark-blue;
      }
    }

    & > li {
      counter-increment: cupcake;

      &::before {
        content: counters(cupcake, ".") ". ";
        font-weight: 600;
      }
    }
  }

  h3 {
    margin: 4rem 0 3rem;
  }

  a {
    text-decoration: underline;
    color: inherit;

    &:hover {
      color: $Accent;
    }
  }

  h5 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  h6 {
    margin-bottom: 1rem;
    font-size: 0.875rem;
  }

  p,
  ul {
    margin-bottom: 1.75rem;
  }

  li:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  ul {
    li::before {
      content: "●";
      color: $Accent;
      font-weight: bold;
      display: inline-block;
      width: 1.2em;

      @include mobile {
        margin-left: unset;
      }
    }
  }
}
