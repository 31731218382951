.activity-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 1rem;
  transition: 0.2s;
  height: 100%;

  @include hover {
    box-shadow: 0 6px 32px rgba($Very-dark-blue, 0.1),
      0 6px 16px -2px rgba($Very-dark-blue, 0.1);

    footer {
      color: $Dark-grayish-blue;

      svg {
        translate: 0.125rem 0;
      }
    }
  }

  .title {
    font-size: 0.75rem;
    color: $Dark-grayish-blue;
    white-space: nowrap;

    @include mobile {
      font-size: 0.875rem;
    }
  }

  .activity-status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    color: $Dark-grayish-blue;
    line-height: 1;

    @include mobile {
      font-size: 1.25rem;
    }

    svg {
      font-size: 1.5rem;
      display: flex;
    }
  }

  .ativity-coeff-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  footer {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.75rem;
    color: rgba($Dark-grayish-blue, 0.8);
    padding-top: 0.5rem;
    border-top: 1px solid $Light-grayish-blue;
    transition: 0.2s;

    svg {
      transition: 0.2s;
    }
  }
}

.activity-coeff {
  font-size: 1rem;
  font-weight: 700;
  background: $Footer-bg;
  color: $Dark-grayish-blue;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;

  &[data-inactive] {
    background: none;
    border: 1px solid $Grayish-blue;
    color: rgba($Dark-grayish-blue, 0.7);
  }
}

.activity-modal {
  width: min(640px, 100%);
  align-items: center;

  @include mobile {
    width: 100vw;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      small {
        font-size: 0.875rem;
        color: $Dark-grayish-blue;
      }
    }
  }

  .activity-status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: $Dark-grayish-blue;

    svg {
      font-size: 1.75rem;
      display: flex;
    }
  }

  details {
    width: 100%;

    &[open] {
      svg {
        rotate: 180deg;
      }
    }

    summary {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.625rem;

      svg {
        color: $Dark-grayish-blue;
        transition: 0.15s;
      }
    }
  }

  .activity-info {
    background: $Background;

    border-radius: 0.5rem;

    summary {
      padding: 1rem;

      span {
        font-size: 0.875rem;
        color: $Dark-grayish-blue;
        flex: 1;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      padding-inline: 1rem;
      padding-block: 1rem;
    }
  }

  .activity-list {
    width: 100%;

    & > *:first-child {
      border-bottom: 1px solid $Light-grayish-blue;
    }
  }

  .activity-table {
    summary {
      padding-block: 1rem;

      h6 {
        flex: 1;
        font-weight: 600;

        span {
          padding: 0.25rem 0.375rem;
          border-radius: 0.5rem;
          background: #00aaf0;
          font-size: 0.75rem;
          color: white;
          margin-left: 0.5rem;
        }
      }
    }

    & > div {
      padding-bottom: 1rem;
    }

    .table-grid {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 1rem;
      align-items: center;
      justify-items: start;
      letter-spacing: 0.008em;

      *:last-child {
        place-self: end;
        align-self: center;
      }

      &-header {
        padding-top: 1.25rem;
        color: $Dark-grayish-blue;
        margin-bottom: 1rem;
      }

      &:not(&-header):not(:last-child) {
        padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;
        border-bottom: 1px solid $Light-grayish-blue;
      }

      .name {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        span {
          align-self: flex-start;
        }

        .date {
          font-size: 0.75rem;
          color: $Grayish-blue;
        }
      }

      .amount {
        font-weight: 600;
      }
    }
  }

  .activity-empty {
    padding: 1rem;
    background: $Background;
    color: $Dark-grayish-blue;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
}
