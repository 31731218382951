.account-bonus {
  margin-bottom: 2.5rem;

  &-header {
    display: grid;
    grid-template-columns: 0.8fr 0.8fr 1fr;
    align-items: center;
    gap: 1rem;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 440px) {
      grid-template-columns: 1fr;
    }
  }
}

.bonus-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 1rem;
  transition: 0.2s;
  background: linear-gradient(45deg, $Accent, hsl(200, 98%, 46%));
  // width: fit-content;
  border-radius: 0.5rem;
  mask-size: 400%;
  mask-position: 0%;
  height: 100%;

  @include hover {
    mask-image: linear-gradient(
      60deg,
      black 25%,
      rgba(white, 0.2) 50%,
      black 75%
    );
    transition: 0.5s;
    mask-position: 110%;

    footer {
      color: rgba(white, 0.8);

      svg {
        translate: 0.125rem 0;
      }
    }
  }

  .title {
    font-size: 0.75rem;
    color: rgba(white, 0.8);
    white-space: nowrap;

    @include mobile {
      font-size: 0.875rem;
    }
  }

  .count {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 1.5rem;
    color: white;
    font-weight: 700;

    svg {
      font-size: 1rem;
    }
  }

  footer {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.75rem;
    color: rgba(white, 0.6);
    padding-top: 0.5rem;
    border-top: 1px solid rgba(white, 0.3);
    transition: 0.2s;

    svg {
      transition: 0.2s;
    }
  }
}

.bonus-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.75rem;
  text-align: right;

  @include tablet {
    grid-column: 1/-1;
  }
}

.bonus-modal {
  .bonus-modal-header {
    align-items: center;
  }

  .bonus-amount {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 2rem;
      color: $Accent;
      font-weight: 700;

      svg {
        font-size: 1.25rem;
      }
    }

    span {
      color: $Dark-grayish-blue;
    }
  }
}

.bonus-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .btn-inline-icon {
    align-self: center;
  }

  .table-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    align-items: center;
    justify-items: start;
    letter-spacing: 0.008em;

    *:last-child {
      place-self: end;
      align-self: center;
    }

    &-header {
      color: $Dark-grayish-blue;
      margin-bottom: 1.25rem;
    }

    &:not(&-header):not(:last-child) {
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
      border-bottom: 1px solid $Light-grayish-blue;
    }

    .operation {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      span {
        align-self: flex-start;
      }

      &-date {
        font-size: 0.75rem;
        color: $Grayish-blue;
      }
    }

    .amount {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      font-weight: 600;

      &[data-income="true"] {
        color: $Green;

        &::before {
          content: "+";
        }
      }

      &[data-income="false"] {
        color: $Red;

        &::before {
          content: "-";
        }
      }

      svg {
        font-size: 0.625rem;
      }
    }
  }

  .btn-primary {
    width: 100%;
    padding-inline: 1.5rem;
  }
}
