.slider-bullets {
  display: grid;
  height: 0.75rem;
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;
  gap: 1rem;
}

.slider-bullet {
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background: $Accent;
  @extend %tr;

  &:hover:not(.active) {
    transform: scale(1.2);
  }

  &.active {
    cursor: unset;
    width: 0.75rem;
    height: 0.75rem;
  }
}
