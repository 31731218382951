.order-container {
  border-top: 1px solid $Grayish-blue;
  margin-top: 1.25rem;
  padding-top: 4rem;

  @include mobile {
    padding-top: 1.5rem;
    border-top: unset;
    margin-top: unset;
  }

  .order-header {
    margin-bottom: 2rem;

    @include mobile {
      margin-bottom: 1.5rem;
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    h4 {
      margin-right: 0.5rem;

      @include mobile {
        grid-row: 1;
      }
    }

    &-date {
      color: $Dark-grayish-blue;
    }
  }

  .order-badge {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    border-radius: 0.375rem;
    color: white;
    letter-spacing: 0.016em;
    white-space: nowrap;

    &.success {
      background: $Green;
    }

    &.partially {
      background: mix($Orange, red, 90%);
    }

    &.failure {
      background: $Red;
    }
  }

  .suit-info {
    &:not(:last-child) {
      .participants-list {
        margin-bottom: 1.25rem;
      }
    }
  }
}
