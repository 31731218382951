.settings-card {
  max-width: 480px !important;
  margin-bottom: 2.5rem;
  padding: 2rem 2rem 1rem;

  &.primary {
    transform-origin: top;
    animation: 0.2s popin forwards;
  }

  @include mobile {
    padding: 1.5rem 1.5rem 1rem;
  }

  @keyframes popin {
    from {
      opacity: 0;
      max-height: 0;
      transform: scale(0.8);
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    to {
      opacity: 1;
      transform: scale(1);
      max-height: 30rem;
    }
  }

  @keyframes popout {
    from {
      opacity: 1;
      transform: scale(1);
      max-height: 30rem;
    }
    to {
      opacity: 0;
      max-height: 0;
      transform: scale(0.8);
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.close {
    animation: 0.2s popout;
  }

  .settings-close {
    color: $Dark-grayish-blue;
  }
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @include mobile {
    margin-bottom: 1.5rem;
  }

  &-back {
    cursor: pointer;
    margin-right: 1rem;
  }

  h5 {
    display: flex;
    align-items: center;
    margin-right: 0.75rem;

    svg {
      margin-right: 0.75rem;
    }
  }
}

.setting-item {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    margin: 0 auto;
    width: 110%;
    height: 100%;
    background: $Background;
    opacity: 0;
    box-shadow: 0 1px 0 $Light-grayish-blue, 0 -1px 0 $Light-grayish-blue;

    @include mobile {
      width: 107%;
      height: 107%;
    }
  }

  @include desktop {
    &:hover {
      &::before {
        opacity: 1;
      }

      svg {
        color: $Dark-grayish-blue;
      }
    }
  }

  &:active {
    &::before {
      opacity: 1;
    }

    .icon-arrow {
      color: $Dark-grayish-blue;
    }
  }

  &:not(:last-child) {
    box-shadow: 0 2px 0 -1px $Light-grayish-blue;
  }

  &-title {
    z-index: 1;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $Dark-grayish-blue;
    letter-spacing: 0.016em;
    flex: 1;
    margin-right: 1rem;
    min-width: 4rem;
  }

  &-value {
    z-index: 1;
    margin-right: 1.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mobile {
      margin-right: 1rem;
    }
  }

  .password-value {
    font-size: 1.25rem;
    letter-spacing: 0.05em;
  }

  .icon-arrow {
    color: $Grayish-blue;
    transform: rotate(180deg);
  }

  .icon-warning {
    margin-right: 0.5rem;
    z-index: 1;
  }
}

.setting-form {
  .field:not(:only-child):not(:last-child) {
    margin-bottom: 1.25rem;
  }

  &-controls {
    display: grid;
    align-items: center;
    justify-items: end;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    @include mobile {
      margin-bottom: 0.5rem;
    }

    &.recover-password-controls {
      margin-top: 0;
    }

    .btn {
      width: 100%;
    }
  }
}
