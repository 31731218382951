.checkout-list-group {
  &:not(:only-of-type):not(:last-of-type) {
    margin-bottom: 2rem;
  }

  .participants-list {
    margin-bottom: 1.25rem;
  }

  .btn-outline {
    width: 100%;
    margin-bottom: 1.25rem;
  }
}

.checkout-list {
  @include tablet {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $Grayish-blue;
  }
}

.checkout-payment {
  padding-top: 1.5rem;
  border-top: 1px solid $Grayish-blue;

  h5 {
    margin-bottom: 1.5rem;

    @include mobile {
      margin-bottom: 1.25rem;
    }
  }

  .payment-select {
    display: grid;
    gap: 0.75rem;
  }

  .warning {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    max-width: 60ch;

    svg {
      font-size: 1rem;
      min-width: 1rem;
    }
  }
}

.checkout-promo {
  padding-top: 1.5rem;

  &-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: $Dark-grayish-blue;
    margin-bottom: 0.75rem;

    svg {
      min-width: 1rem;
    }
  }

  &-success {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    height: 2rem;

    &[data-event] {
      height: 3rem;
      padding: 1rem;
      border-radius: 0.5rem;
      background: white;
      box-shadow: 0 3px 22px rgba($Very-dark-blue, 0.05);
    }

    &-text {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      color: $Accent;

      svg {
        font-size: 1rem;
        min-width: 1rem;
      }

      span {
        font-weight: 600;
      }
    }
  }

  h5 {
    margin-bottom: 1rem;
  }

  .field {
    position: relative;

    input {
      min-height: 3rem;
      background: none;
      padding-right: 6.5rem;
      width: 100%;
    }

    .btn-upper {
      position: absolute;
      top: 1px;
      right: 0;
      height: 3rem;
      padding: 0 1rem;
    }
  }
}
