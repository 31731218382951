.suits-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.25rem;

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  @import "empty-list";
}

@import "suit-card";
