.reservation-details-container {
  position: sticky;
  top: 5rem;

  @include tablet {
    position: unset;
    margin-top: 1.5rem;
  }

  .btn-primary-icon {
    width: 100%;
  }

  .warning {
    display: flex;
    align-items: center;
    color: $Dark-grayish-blue;
    margin-bottom: 1rem;

    svg {
      margin-right: 0.75rem;
      font-size: 1.25rem;
      min-width: 1.25rem;
    }
  }
}

.reservation-details-card {
  overflow: hidden;
  margin-bottom: 1rem;
  padding: 1.5rem 1.25rem 1.75rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .btn-delete {
    svg {
      margin: unset;
    }
  }

  .time {
    color: $Grayish-blue;
  }
}

.details-card {
  &-group {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &:not(:only-of-type):not(:last-of-type) {
      padding-bottom: 1rem;
      border-bottom: 1px solid $Grayish-blue;
    }
  }

  &-suit {
    &:not(:only-of-type):not(:last-of-type) {
      padding-bottom: 1.25rem;
      border-bottom: 1px solid $Grayish-blue;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      strong {
        font-weight: 600;
      }
    }
  }
}
