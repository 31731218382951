.numbers-container {
  display: flex;

  &[data-error] {
    .radio-btn-number {
      border-color: $Red;
      color: $Red;

      &:hover {
        box-shadow: 0 0 0 4px rgba($Red, 0.1);
        border-color: $Red;
        color: $Red;
      }
    }
  }
}

.radio-btn-number {
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  color: $Dark-grayish-blue;
  border: 1px solid $Grayish-blue;
  border-radius: 2rem;

  &:hover {
    box-shadow: 0 0 0 4px rgba($Accent, 0.1);
    border-color: $Accent;
    color: $Accent;
  }

  &.active {
    border: none;
    background: $Accent;
    color: white;
    font-weight: 600;
  }

  &.disabled {
    background: $Background;
    cursor: not-allowed;
    color: $Grayish-blue;
    border: none;

    &:hover {
      box-shadow: none;
      color: $Grayish-blue;
    }
  }

  &:not(:last-child) {
    margin-right: 0.625rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
