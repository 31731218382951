.event-info {
  max-width: 580px;
  // margin: 0 auto 4rem;
  margin-inline: auto;

  &-nav {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 1px solid $Light-grayish-blue;
    margin-bottom: clamp(1.5rem, 4.1667vw, 2.5rem);

    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      display: block;
      height: 0.125rem;
      width: 50%;
      background: $Accent;
      transition: 0.3s;
    }

    &.tab-1 {
      &::after {
        left: 50%;
      }
    }
  }
}

.event-description {
  &-text {
    position: relative;
    margin-bottom: 1rem;
    max-height: 15rem;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 100%;
      height: 6rem;
      background: linear-gradient(rgba($Background, 0), $Background);
      pointer-events: none;
    }

    &[data-skeleton] {
      min-height: 15rem;
    }

    &[data-show],
    &[data-skeleton] {
      max-height: unset;

      &::after {
        display: none;
      }

      .event-gallery {
        display: flex;
      }

      img {
        display: block;
      }
    }

    & > :last-child {
      margin: unset;
    }

    a {
      text-decoration: underline;
      color: inherit;

      &:hover {
        color: $Accent;
      }
    }

    .event-gallery {
      display: none;
      margin-bottom: 2rem;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block: 2.5rem 1.5rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }

  p,
  ul {
    margin-bottom: 1.5rem;
  }

  ul {
    li {
      display: flex;
      gap: 0.5rem;
      max-width: 60ch;

      &::before {
        content: "●";
        color: $Accent;
        font-weight: bold;
        display: inline-block;
      }

      &:not(:last-child) {
        margin-bottom: 0.625rem;
      }
    }
  }

  img {
    max-width: 100%;
    display: none;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th {
      font-weight: 500;
      font-size: 0.75rem;
      color: $Dark-grayish-blue;
      padding-bottom: 0.25rem;
    }

    td {
      padding-block: 0.75rem;
    }

    th,
    td {
      &:not(:first-child):not(:last-child) {
        padding-inline: 1rem;
      }

      &:first-child {
        padding-right: 1rem;
        text-align: left;
      }

      &:last-child {
        text-align: right;
        padding-left: 1rem;
      }
    }

    tr:not(:last-child) {
      td {
        border-bottom: 1px solid $Grayish-blue;
      }
    }
  }

  .p-skeleton {
    height: 1rem;
    border-radius: 0.25rem;
    background: $Light-grayish-blue;

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    &:last-child {
      width: 50%;
    }
  }
}

.event-participants {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  &-item {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;

    &.trainers-list {
      gap: 1rem;
    }

    .trainer {
      display: flex;
      gap: 0.5rem;

      &-name {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        &-text {
          display: flex;
          gap: 0.375rem;
          flex-direction: column;
        }

        .sport {
          display: flex;
          align-items: center;
          gap: 0.375rem;
          color: $Dark-grayish-blue;

          svg {
            color: $Grayish-blue;
          }

          span {
            font-size: 0.75rem;
            font-weight: 500;
          }
        }
      }

      &-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.375rem;
      }

      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
      }

      span {
        font-weight: 600;
      }
    }
  }

  code {
    font-family: monospace, sans-serif;
    text-transform: lowercase;
    white-space: nowrap;
    font-size: 0.8125rem;
  }

  li {
    margin-left: 1rem;

    &::marker {
      color: $Dark-grayish-blue;
      font-size: 0.75rem;
    }
  }

  // li {
  //   &::before {
  //     content: "●";
  //     color: $Accent;
  //     font-weight: bold;
  //     display: inline-block;
  //     width: 1.2em;
  //   }

  //   &.inactive::before {
  //     color: $Light-grayish-blue;
  //   }
  // }
}

.event-refund {
  &[open] {
    svg {
      rotate: 0deg;
    }
  }

  summary {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    list-style-type: none;
    cursor: pointer;
    width: fit-content;

    @include hover {
      text-decoration: underline;
    }

    &::webkit-details-marker {
      display: none;
    }

    svg {
      rotate: -90deg;
      transition: 0.15s;
      min-width: 0.75rem;
    }
  }

  table {
    margin-top: 1.5rem;
  }
}
