*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  background: $Background;
  // scrollbar-gutter: stable;
  // overflow: overlay;
}

img {
  display: block;
  user-select: none;
}

::-webkit-details-marker {
  display: none;
}
