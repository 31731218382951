.loader {
  position: fixed;
  top: 0;
  z-index: 10;
  height: 0.25rem;
  width: 100%;
  background: mix($Accent, white, 30%);
  display: none;

  &.active {
    display: unset;

    .loader-progress {
      height: 100%;
      background: $Accent;
      width: 80%;
      animation: 1s loader infinite;
    }
  }

  @keyframes loader {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
}
