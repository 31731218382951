.coupon-activation-modal {
  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    input {
      text-transform: uppercase;
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;

    .btn-primary {
      width: 100%;
    }
  }

  .activation-success {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    svg {
      font-size: 3rem;
      color: $Accent;
      margin-inline: auto;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
