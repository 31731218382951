.field {
  display: flex;
  flex-direction: column;

  label {
    pointer-events: none;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  input,
  textarea {
    outline: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    border: 1px solid $Grayish-blue;
    background: none;
    border-radius: 0.375rem;
    min-height: 2.25rem;
    padding: 0 0.75rem;
    -webkit-appearance: none;

    &:hover {
      box-shadow: 0 0 0 4px rgba($Accent, 0.05);
    }

    &::placeholder {
      color: $Grayish-blue;
    }

    &[type="password"] {
      letter-spacing: 0.05em;
    }

    &[disabled] {
      background: $Background;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    }

    &:focus {
      border-color: $Accent;
      box-shadow: 0 0 0 4px rgba($Accent, 0.1);
    }
  }

  textarea {
    padding-block: 0.5rem;
    line-height: 1.5;
    resize: none;
    scrollbar-width: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.error {
    input {
      border-color: $Red;

      &:hover,
      &:focus {
        box-shadow: 0 0 0 4px rgba($Red, 0.1);
      }
    }
  }
}

.radio-container {
  display: flex;
  flex-direction: column;

  strong {
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  &.error {
    .radio-btn {
      &:hover input ~ .checkmark {
        box-shadow: 0 0 0 4px rgba($Red, 0.1);
      }

      input {
        &:focus ~ .checkmark {
          box-shadow: 0 0 0 4px rgba($Red, 0.1);
        }
      }

      .checkmark {
        border-color: $Red;
      }
    }
  }

  .error-msg,
  .helper-text {
    margin-top: 0.75rem;
  }

  .radio-btn {
    position: relative;
    user-select: none;
    cursor: pointer;
    color: $Dark-grayish-blue;
    letter-spacing: 0.008em;
    width: fit-content;
    padding-left: 1.75rem;

    &:not(:last-of-type) {
      margin-bottom: 0.75rem;
    }

    &:hover input ~ .checkmark {
      box-shadow: 0 0 0 4px rgba($Accent, 0.05);
    }

    &.disabled {
      color: $Grayish-blue;
      cursor: not-allowed;

      &:hover input ~ .checkmark {
        box-shadow: none;
      }

      .checkmark {
        border-color: $Grayish-blue;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:focus ~ .checkmark {
        box-shadow: 0 0 0 4px rgba($Accent, 0.1);
      }

      &:checked ~ .checkmark {
        border-color: $Accent;

        &::after {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 1rem;
      border: 1px solid $Dark-grayish-blue;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: $Accent;
        transition: 0.1s;
      }
    }
  }
}

.checkbox-container {
  display: flex;

  .checkbox {
    position: relative;
    user-select: none;
    cursor: pointer;
    color: $Dark-grayish-blue;
    letter-spacing: 0.008em;
    width: fit-content;
    padding-left: 1.75rem;

    &:hover input ~ .checkmark {
      box-shadow: 0 0 0 4px rgba($Accent, 0.05);
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:focus ~ .checkmark {
        box-shadow: 0 0 0 4px rgba($Accent, 0.1);
      }

      &:checked ~ .checkmark {
        border-color: none;
        background: $Accent;

        &::after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 0.25rem;
      border: 1px solid $Dark-grayish-blue;

      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 3px;
        width: 5px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.error-msg,
.helper-text,
.form-link {
  font-size: 0.75rem;
}

.helper-text-dark,
.form-link,
.not-confirmed {
  margin-top: 0.5rem;
}

.error-msg,
.helper-text {
  margin-top: 0.25rem;
}

.helper-text-dark {
  display: block;
  color: $Dark-grayish-blue;
}

.helper-text {
  color: $Grayish-blue;
}

.form-link {
  max-width: fit-content;
}

.error-msg {
  color: rgba($Red, 0.8);
  transform-origin: left;
  animation: 0.15s error;

  @keyframes error {
    from {
      margin-top: 0;
      opacity: 0.5;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.required-mark {
  color: $Red;
  animation: 0.15s reqMark forwards;
  opacity: 0;

  @keyframes reqMark {
    to {
      opacity: 1;
    }
  }
}

.not-confirmed {
  display: flex;
  align-items: center;
  color: $Dark-grayish-blue;

  svg {
    font-size: 1.5rem;
    margin-right: 0.75rem;
  }
}
