.main-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 4rem;

  .slider-container {
    position: relative;
    width: 100%;
    border-radius: 0.625rem;
    overflow: hidden;
    background: $Accent;
    transform-style: preserve-3d;

    .prev,
    .next {
      width: fit-content;
    }

    .prev {
      padding-left: 1rem;
      left: 0;
    }

    .next {
      padding-right: 1rem;
      right: 0;
    }

    @include mobile {
      background: no-repeat url("../../../icons/slider-bg-right.svg") right top,
        $Accent;

      .prev,
      .next {
        display: none;
      }
    }
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination {
    margin-top: unset;
  }

  .swiper-slide-active {
    .slide-description {
      background-position: left bottom, right top;
      // animation: 0.5s bgSlide forwards;
    }
  }

  .slide {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    min-height: 360px;
    height: 100%;

    overflow: hidden;

    @include mobile {
      display: flex;
      min-height: unset;
    }

    &-description {
      display: flex;
      align-items: center;
      min-width: 360px;
      padding: 2rem 5rem 2rem 4rem;
      background: no-repeat url("../../../icons/slider-bg-left.svg") left -100% bottom -100%,
        no-repeat url("../../../icons/slider-bg-right.svg") right -100% top -100%;
      color: white;
      transition: 0.5s;

      @include tablet {
        background-size: 70%, 60%;
      }

      @include mobile {
        width: 100%;
        min-width: unset;
        padding: 2rem 1.5rem;
        background: unset;
      }
    }

    .description-content {
      margin: 0 auto;

      @include mobile {
        min-height: 10rem;
      }
    }

    &-title {
      font-size: 2rem;
      margin-bottom: 0.5rem;
      animation: 0.5s bounce;
    }

    &-date {
      display: flex;
      letter-spacing: 0.016em;
      color: rgba(white, 0.6);
      animation: 0.7s fadein;
      white-space: nowrap;
    }

    &-text {
      max-width: 60ch;
      margin-top: clamp(1.25rem, 0.75rem + 1vw, 1.375rem);
      margin-bottom: clamp(1.5rem, -2.5rem + 8.3vw, 2rem);
      animation: 0.7s fadein;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    &-img {
      cursor: pointer;
      position: relative;
      overflow: hidden;
      margin-left: -5rem;
      clip-path: polygon(5rem 0, 100% 0, 100% 100%, 0% 100%);
      background: $Accent;

      @include mobile {
        display: none;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        clip-path: polygon(5rem 0, 100% 0, 100% 100%, 0% 100%);
        animation: 0.5s bounce;
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}
