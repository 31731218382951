.package-dates {
  display: flex;
  margin-top: 0.75rem;

  p {
    margin: 0.25rem 0 0.125rem;
    letter-spacing: 0.008em;
    line-height: 1.4em;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  small {
    font-size: 0.75rem;
    color: $Dark-grayish-blue;
  }

  .package-date:first-child {
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid $Grayish-blue;

    @include mobile {
      padding-right: 0.75rem;
      margin-right: 0.75rem;
    }
  }
}
