.summary-container {
  position: sticky;
  top: 5rem;

  @include tablet {
    position: unset;
  }

  .summary-card {
    padding: 1.375rem 0 1rem;
    margin-bottom: 1rem;

    &.success {
      padding-bottom: 0;
      overflow: hidden;
    }
  }

  .summary-event-group {
    padding: 0 1.5rem;
    margin-bottom: 1rem;

    @include mobile {
      padding: 0 1.25rem;
    }
  }

  h6 {
    margin-bottom: 0.75rem;
  }

  ul {
    background: $Background;
    padding: 0.75rem;
    border-radius: 0.375rem;
    margin-bottom: 0.75rem;
  }

  .table-header {
    color: $Grayish-blue;
    margin-bottom: 0.5rem;
  }

  li {
    display: flex;
    justify-content: space-between;
    color: $Dark-grayish-blue;
  }

  .summary-subtotal {
    // margin-right: 0.75rem;
    // text-align: right;
    // display: grid;
    // grid-template-columns: 5fr 1fr;
    // gap: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
    color: $Dark-grayish-blue;

    &:not(:last-child):not(:only-child) {
      margin-bottom: 0.5rem;
    }

    &-row {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.25rem;
      width: 100%;
    }
  }

  .summary-price {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    gap: 1rem;
    font-weight: 600;
    white-space: nowrap;

    small {
      font-weight: 500;
      color: $Dark-grayish-blue;
    }

    &.paid {
      color: $Green;
    }

    &.failure {
      color: $Red;
    }

    &-discount {
      &[data-show] {
        .discount-list {
          display: block;
        }

        svg {
          rotate: 180deg;
        }
      }

      &-row {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.25rem;

        del {
          font-size: 0.75rem;
          opacity: 0.8;
        }
      }

      .discount-list {
        margin-bottom: unset;
        background: unset;
        padding: 0.75rem 0;
        display: none;

        li {
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 0.75rem;
          line-height: 1.5;
        }
      }
    }

    &-badge {
      padding: 0.25rem;
      background: $Green;
      border-radius: 0.25rem;
      color: white;
      font-size: 0.625rem;
    }
  }

  li:not(.table-header):not(:last-child):not(:only-child) {
    border-bottom: 1px solid $Grayish-blue;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .summary-total {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-top: 1rem;
    margin: 0 1.5rem;
    border-top: 1px solid $Grayish-blue;

    @include mobile {
      margin: 0 1.25rem;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.75rem;
    }

    .bonus-inline {
      display: flex;
      align-items: center;
      gap: 0.125rem;
      font-weight: 700;

      svg {
        font-size: 0.5rem;
      }
    }

    .checkmark {
      color: $Accent;
      font-size: 1rem;
    }

    .bonus-badge {
      display: flex;
      align-items: center;
      gap: 0.125rem;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      background: linear-gradient(45deg, $Accent, hsl(200, 98%, 46%));
      font-size: 0.75rem;
      font-weight: 700;
      color: white;

      svg {
        font-size: 0.5rem;
      }
    }

    &-final {
      font-size: 1.25rem;
      font-weight: 700;
    }

    .final-price {
      display: flex;
      align-items: flex-end;
      gap: 0.375rem;

      small {
        font-size: 0.75rem;
        font-weight: 500;
        color: $Dark-grayish-blue;
        text-decoration: line-through;
      }
    }
  }

  .summary-invoices {
    padding-top: 1rem;
    margin: 0 1.5rem;
    border-top: 1px solid $Grayish-blue;

    @include mobile {
      margin: 0 1.25rem;
    }

    h5 {
      margin-bottom: 1rem;
    }

    li {
      display: grid;
      grid-template-columns: 4fr auto minmax(useClamp(768, 1120, 3, 7), 1fr);
      gap: 0.75rem 1rem;
      align-items: center;

      @include tablet {
        grid-template-columns: 4fr minmax(3rem, 1fr) 1fr;
      }

      &:not(.table-header):not(:last-child):not(:only-child) {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }

      p {
        font-size: 0.75rem;
        line-height: 1.5em;
      }

      form {
        grid-column: 1 / 4;

        button {
          width: 100%;
        }
      }

      .payment-status {
        line-height: 1.7;

        i {
          background: rgba(220, 221, 224, 0.4);
          border-radius: 0.25rem;
          padding: 0.2em 0.4em;
          font-style: normal;
          font-size: 0.625rem;
        }
      }

      small:last-child,
      .payment-status {
        justify-content: flex-end;
        text-align: right;
      }
    }

    .balance-container {
      font-size: 0.75rem;
      grid-column: 1/-1;
      margin-top: unset;
    }
  }

  .summary-info {
    color: $Dark-grayish-blue;
    display: block;
    margin-bottom: 1rem;
  }

  .summary-success {
    display: flex;
    justify-content: center;
    background: $Green;
    color: white;
    padding: 1rem;

    svg {
      font-size: 1rem;
      margin-right: 0.75rem;
    }
  }

  .btn-primary-icon {
    width: 100%;
  }

  .balance-container {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;

    .btn-balance {
      color: $Dark-grayish-blue;

      @include desktop {
        &:hover {
          color: $Very-dark-blue;
          text-decoration: underline;
        }
      }
    }
  }
}
