.service-msg {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 5rem;
  right: 50%;
  z-index: 100;
  padding: 1rem 1.5rem 1rem 1rem;
  animation: slide 3s ease forwards;
  pointer-events: none;
  border-radius: 0.5rem;
  width: fit-content;
  max-width: 80vw;

  @include tablet {
    bottom: 2rem;
  }

  p {
    max-width: 800px;
  }

  svg {
    font-size: 1.25rem;
    min-width: 1.25rem;
    margin-right: 0.75rem;
  }

  &-success {
    background: rgba(mix($Accent, white, 15%), 0.8);
    border: 1px solid $Accent;
    color: mix($Accent, black, 40%);
  }

  &-error {
    background: rgba(mix($Red, white, 15%), 0.8);
    border: 1px solid $Red;
    color: mix($Red, black, 70%);
  }

  @keyframes slide {
    0% {
      transform: translate(50%, 300%);
    }
    10% {
      transform: translate(50%, 0);
    }
    90% {
      transform: translate(50%, 0);
    }
    100% {
      transform: translate(50%, 300%);
    }
  }
}
