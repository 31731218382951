.tg-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  background: $Footer-bg;
  max-width: 980px;
  margin-inline: auto;
  padding: 2rem;
  border-radius: 0.5rem;
  margin-bottom: 4rem;

  @include mobile {
    padding: 1.5rem 1.375rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  svg {
    margin-bottom: 0.5rem;
  }

  p {
    max-width: 50ch;
    text-align: center;
    color: $Dark-grayish-blue;

    @include mobile {
      text-align: left;
    }
  }

  .btn {
    max-width: fit-content;
  }
}
