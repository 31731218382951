.header {
  position: sticky;
  top: 0;
  z-index: 2;
  margin-block: 1rem;

  &.active {
    &::before {
      content: "";
      position: absolute;
      height: 100vh;
      width: 100%;
      background-image: linear-gradient(
        rgba($Very-dark-blue, 0.8) 30%,
        transparent
      );
      transform: translateZ(-2px);
    }

    .header-wrapper {
      background: white;
      box-shadow: 0 -1rem 0 white;
    }

    .container {
      display: grid;
      row-gap: 3rem;
    }

    .logo {
      grid-row: 1;
    }

    .header-nav {
      display: flex;
    }
  }

  .header-wrapper {
    position: relative;
    background: rgba($Background, 0.9);
  }

  .container {
    max-width: 980px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 1.5rem;

    @include mobile {
      padding-block: 1.25rem;
    }
  }

  .logo {
    margin-right: 3rem;
    display: flex;
  }
}

.header-nav {
  display: grid;
  grid-auto-flow: column;
  gap: 2rem;

  @include mobile {
    position: absolute;
    top: 64px;
    left: 0;
    background: white;
    padding: 1rem 1.5rem;
    border-radius: 0 0 1rem 1rem;
    width: 100%;
    flex-direction: column;
    gap: unset;

    display: none;
  }

  a {
    color: $Dark-grayish-blue;

    @include tablet {
      &.signup-link {
        display: none;
      }
    }

    @include mobile {
      width: 100%;
      font-size: 1rem;
      padding: 1.25rem 0;

      &.signup-link {
        display: unset;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $Light-grayish-blue;
      }
    }

    &:hover {
      color: $Very-dark-blue;
      text-decoration: underline;

      @include mobile {
        text-decoration: unset;
      }
    }
  }
}

.reservation-link {
  position: relative;
  display: flex;
  transition: margin 0.3s ease;

  .reservation-number {
    right: -1.125rem;
    top: -0.25rem;

    @include mobile {
      position: relative;
      right: unset;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.75rem;
      transition: unset;
      margin-left: 0.75rem;
    }
  }

  &.active {
    margin-right: 1rem;

    .reservation-number {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.reservation-number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: $Accent;
  font-size: 0.5rem;
  color: white;
  line-height: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  opacity: 0;
  transform: scale(0.5);
  transition: 0.3s ease;
}

.header-user {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;

  @include mobile {
    max-width: unset;
  }

  &:hover {
    svg {
      color: $Dark-grayish-blue;
    }
  }

  svg {
    position: relative;
    top: 0.125em;
    margin-right: 0.5rem;
    color: $Grayish-blue;
  }
}

.mobile-controls {
  grid-row: 1;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.shopping-cart {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    @extend %tr;
  }

  @include hover {
    svg {
      color: $Very-dark-blue;
    }
  }

  .reservation-number {
    right: -0.375rem;
    top: -0.25rem;
    outline: 0.125rem solid $Background;
  }

  &[data-active] {
    .reservation-number {
      opacity: 1;
      transform: scale(1);
    }
  }

  svg {
    font-size: 1.5rem;
    color: $Dark-grayish-blue;
  }
}

.hamburger {
  cursor: pointer;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include hover {
    .hamburger-icon {
      background: $Very-dark-blue;

      &::before,
      &::after {
        background: $Very-dark-blue;
      }
    }
  }

  &-icon {
    position: relative;
    top: -0.3px;
    width: 1.5rem;
    height: 0.125rem;
    background: $Dark-grayish-blue;
    pointer-events: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 1.5rem;
      height: 0.125rem;
      background: $Dark-grayish-blue;
      @extend %tr;
    }

    &::before {
      bottom: 6.3px;
    }

    &::after {
      top: 6.3px;
    }
  }

  &.active {
    .hamburger-icon {
      background: transparent;

      &::before {
        bottom: 0;
        transform: rotate(45deg);
      }

      &::after {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }
}
