.reservation-card {
  overflow: hidden;
  transition: 0.3s ease;
  margin-top: 2rem;

  @include tablet {
    margin-top: 1.25rem;
  }

  &.deleting {
    overflow: hidden;
    animation: 0.3s deleting forwards;

    @keyframes deleting {
      from {
        max-height: 50rem;
      }
      to {
        max-height: 0;
        padding: 0;
        margin: 0;
        opacity: 0;
        transform: scale(0.5);
      }
    }
  }

  &-header {
    margin-bottom: 1.25rem;
  }

  .primary-selects {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    padding-top: 2rem;
    border-top: 1px solid $Light-grayish-blue;

    &[data-hidden] {
      display: none;
    }

    @import "radio-room-type";
    @import "radio-numbers";
  }

  .participant-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid $Light-grayish-blue;
    margin-top: 1.375rem;
    padding-top: 1.625rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    legend {
      margin-bottom: 0.5rem;
      width: 100%;

      .legend-header {
        display: flex;
        align-items: center;
        gap: 1rem;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          width: 1.75rem;
          height: 1.75rem;
          border-radius: 0.25rem;
          font-size: 1rem;
          color: $Grayish-blue;

          @include hover {
            color: white;
            outline: unset;
            background: #f87171;
          }
        }
      }

      .btn-fill {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        color: $Dark-grayish-blue;
        background: $Background;
        padding: 0.875rem 1.5rem;
        border-radius: 0.5rem;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: -0.5rem;
        max-width: 20rem;

        @include hover {
          outline: 1px solid $Grayish-blue;
        }

        svg {
          font-size: 1rem;
        }
      }
    }

    .field {
      max-width: 20rem;
      width: 100%;

      input[type="number"] {
        max-width: 5rem;
      }
    }
  }

  .add-participant {
    width: 100%;
    border-top: 1px solid $Light-grayish-blue;
    margin-top: 1.375rem;
    padding-top: 1.625rem;

    button {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        font-size: 1rem;
      }
    }
  }
}
