.suit-card {
  display: flex;
  flex-direction: column;
  width: min(380px, 100%);
  margin-inline: auto;

  .swiper {
    height: 13.75rem;
    overflow: hidden;
    border-radius: 0.5rem 0.5rem 0 0;

    @include desktop {
      &:hover {
        .swiper-pagination {
          opacity: 1;
        }
      }
    }

    @include mobile {
      height: 12rem;
    }

    &-pagination {
      position: absolute;
      z-index: 2;
      bottom: 0.5rem;
      margin: 0;
      gap: 0.75rem;

      @include desktop {
        transition: 0.2s;
        opacity: 0;
      }

      &-bullet {
        background: white;
      }
    }
  }

  &-info {
    display: flex;
    gap: 0.5rem;
    padding-block: 0.25rem;

    svg {
      font-size: 1rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      overflow: hidden;

      &:not(:only-child):not(:last-child) {
        padding-right: 0.5rem;
        border-right: 1px solid $Grayish-blue;
      }
    }

    .info-text {
      font-size: 0.75rem;
      color: $Dark-grayish-blue;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    padding: 1.5rem;

    @include mobile {
      padding: 1.5rem 1.125rem;
    }

    &-title {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      i {
        margin-top: -0.5rem;
        width: fit-content;
        background: $Footer-bg;
        padding: 0.25rem 0.5rem;
        border-radius: 0.375rem;
        font-style: normal;
        font-size: 0.75rem;
        color: $Dark-grayish-blue;
      }

      h5 {
        span {
          font-size: 0.75rem;
          font-weight: 600;
          line-height: 2;
          color: $Dark-grayish-blue;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex: 1;
    }

    li {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      color: $Dark-grayish-blue;
      letter-spacing: unset;

      .rate-text {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        small {
          color: $Grayish-blue;
        }
      }

      .rate-price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        white-space: nowrap;

        span:last-child:not(:only-child) {
          font-size: 0.625rem;
          color: $Grayish-blue;
          text-decoration: line-through;
          line-height: 1.2;
        }
      }

      // span:first-child {
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }
    }
  }

  &-services {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $Light-grayish-blue;

    b {
      font-weight: 600;
    }

    .services-list {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      span {
        white-space: nowrap;
        color: $Dark-grayish-blue;
      }
    }
  }

  .remaining {
    padding: 0.625rem 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid rgba($Accent, 0.6);
    background: rgba($Accent, 0.05);
    color: $Accent;
  }

  .discount {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;

    details {
      background: $Background;
      padding: 0.5rem;
      border-radius: 0.5rem;
      width: 100%;

      &[open] {
        svg {
          rotate: 180deg;
        }
      }

      summary {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.625rem;

        .badge {
          padding: 0.25rem;
          background: $Green;
          border-radius: 0.25rem;
          font-weight: 600;
          white-space: nowrap;
          color: white;
          font-size: 0.625rem;
        }

        span {
          font-size: 0.75rem;
          color: $Dark-grayish-blue;
          flex: 1;
        }

        svg {
          color: $Dark-grayish-blue;
          transition: 0.15s;
        }
      }

      ul {
        padding-top: 1.25rem;
      }

      li {
        &:not(:last-child) {
          padding-bottom: 0.25rem;
          border-bottom: 1px solid $Light-grayish-blue;
        }
        span {
          font-size: 0.75rem;

          &:last-child {
            font-weight: 600;
          }
        }
      }
    }

    .text-link {
      font-size: 0.75rem;
    }
  }
}
