.balance-modal {
  width: 100vw;

  ul {
    background: $Background;
    padding: 1rem;
    border-radius: 0.375rem;

    li {
      display: flex;
      justify-content: space-between;

      &:first-child {
        border-bottom: 1px solid $Grayish-blue;
        padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;
      }
    }
  }

  input {
    &[type="number"] {
      appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
