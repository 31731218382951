.events {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 2rem;
  max-width: 980px;
  margin: 0 auto;

  @include mobile {
    grid-template-columns: unset;
    gap: 1.25rem;
  }
}

.event-card {
  place-self: center;
  display: grid;
  grid-template-rows: auto 1fr;
  width: min(30rem, 100%);
  min-height: 100%;
  overflow: hidden;
  transform-style: preserve-3d;

  @include desktop {
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }

  &[data-skeleton] {
    .event-card-header {
      background: $Light-grayish-blue;
    }

    .event-card-date {
      background: $Light-grayish-blue;
      color: transparent;
      border-radius: 0.25rem;

      &::before {
        visibility: hidden;
      }
    }

    h5 {
      background: $Light-grayish-blue;
      color: transparent;
      border-radius: 0.25rem;
      width: 50%;
    }

    .btn-primary {
      background: $Light-grayish-blue;
      color: transparent;
    }
  }

  &-header {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    height: clamp(10rem, 5.3125rem + 18.75vw, 13.75rem);
    background: $Accent;

    &[data-soldout] {
      background: #003066;

      img,
      video {
        opacity: 0.3;
      }
    }

    @import "countdown";

    .soldout {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      text-align: center;
      color: white;
      font-size: 9rem;
      rotate: -15deg;

      @include mobile {
        font-size: 7rem;
      }
    }

    // h2 {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   width: 80%;
    //   transform: translate(-50%, -50%);
    //   text-align: center;
    //   color: white;
    //   font-size: 2rem;

    //   @include mobile {
    //     font-size: 1.5rem;
    //   }
    // }

    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      // opacity: 0.7;
      transition: 0.5s;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.375rem 2rem 2rem;

    @include mobile {
      padding: 1rem 1.375rem 1.375rem;
    }
  }

  &-date {
    display: flex;
    align-items: center;
    color: $Dark-grayish-blue;
    letter-spacing: 0.016em;
    margin-bottom: 1rem;
    white-space: nowrap;

    @include mobile {
      font-size: 0.75rem;
      margin-bottom: 0.75rem;
    }

    &::before {
      content: "";
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 1rem;
      background: $Accent;
      margin-right: 0.875rem;

      @include mobile {
        width: 0.375rem;
        height: 0.375rem;
        margin-right: 0.625rem;
      }
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.25rem;
    flex: 1;

    i {
      background: $Footer-bg;
      padding: 0.25rem 0.5rem;
      border-radius: 0.375rem;
      font-style: normal;
      font-size: 0.75rem;
      color: $Dark-grayish-blue;
    }
  }

  &-sport {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.125rem;
    margin-top: 0.25rem;

    span {
      font-size: 1.25rem;
    }
  }

  .btn-primary {
    max-width: fit-content;

    @include mobile {
      max-width: unset;
    }
  }
}
