.contact-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;

  .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 600px;
  }

  h3 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: useClamp(576, 768, 2, 4);

    @include mobile {
      text-align: unset;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    padding-bottom: 3rem;
    border-bottom: 1px solid $Grayish-blue;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      @include mobile {
        width: fit-content;
        padding-left: unset;
        border-left: unset;
      }

      li {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        @include mobile {
          flex-direction: column;
          justify-content: unset;
          gap: 0.25rem;
        }

        &:first-child {
          strong {
            font-size: 1.25rem;
          }
        }

        a {
          color: currentColor;
          text-decoration: underline;

          &:hover {
            color: $Accent;
          }
        }

        span {
          font-size: 1rem;
          color: $Dark-grayish-blue;

          @include mobile {
            font-size: 0.875rem;
          }
        }

        strong {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }

    .tg-banner {
      margin: unset;
      width: 100%;
    }
  }

  .org {
    background: $Footer-bg;
    border-radius: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include mobile {
      padding: 1.5rem;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @include mobile {
        gap: 0.75rem;
      }
    }

    li {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      @include mobile {
        flex-direction: column;
        gap: 0.125rem;
      }

      span {
        color: $Dark-grayish-blue;
      }

      a {
        color: currentColor;
        text-decoration: underline;

        &:hover {
          color: $Accent;
        }
      }

      strong {
        font-weight: 600;
        text-align: right;

        @include mobile {
          text-align: initial;
        }
      }
    }
  }
}
