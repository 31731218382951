.reservation-expiration {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;

  &-time {
    font-variant-numeric: tabular-nums;
    font-weight: 600;
    line-height: 1.125rem;
  }

  svg {
    font-size: 1.5rem;
    color: $Accent;
  }
}
